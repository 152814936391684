















































import { getProCommit } from "@/api/api";
import { CommitDataState } from "@/api/model";
import Vue from "vue";
import MImg from "../Page/MImg.vue";
export default Vue.extend({
  components: { MImg },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      list: <CommitDataState[]>[],
      total: 0,
      page: 1,
      loading:true,
      empty:false
    };
  },
  created() {
    this.getData();
    console.log("====");
  },
  methods: {
    getData() {
      this.loading = true
      getProCommit(this.id, this.page).then((res) => {
        this.total = res.total;
        this.list = res.list;
      }).finally(()=>{
        this.loading = false
        if(this.total==0){
          this.empty = true
        }
      });
    },
    changePage(e: number) {
      this.page = e;
      this.getData();
    },
  },
});

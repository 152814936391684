

































import { TimeLine } from "@/api/model";
import Vue, { PropType } from "vue";
import MImg from "../Page/MImg.vue";
export default Vue.extend({
  components: { MImg },
  props: {
    timeLine: {
      type: Array as PropType<TimeLine[]>,
      required: true,
    },
  },
  data() {
    return {
      loading:true
    }
  },
  created(){
      
  },
  mounted(){
    this.$nextTick(()=>{
      this.loading = false
    })
  }
});

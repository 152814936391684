















































































































































import Vue, { PropType } from "vue";
import ProType from "@/components/ProType/ProType.vue";
import { ProItemDataState, ProTypeState } from "@/api/model";
import { getProDetail, getProType } from "@/api/api";
import Card from "@/components/Page/card.vue";
import Pay from "@/components/ProDetail/pay.vue";
import Prograss from "@/components/ProDetail/prograss.vue";
import Commit from "@/components/ProDetail/commit.vue";
import Infor from "@/components/ProDetail/Infor.vue";
import VueQr from "vue-qr";
import HotPro from "@/components/ProDetail/HotPro.vue";
import MainContent from "@/components/Page/mainContent.vue";
import MImg from "@/components/Page/MImg.vue";
import { Loading } from "element-ui";
export default Vue.extend({
  components: {
    ProType,
    Card,
    Pay,
    Prograss,
    Commit,
    Infor,
    HotPro,
    MainContent,
    MImg,
    VueQr,
  },
  data() {
    return {
      id: 0,
      proTypeId: 0,
      calssifyData: <ProTypeState[]>[],
      detail: <ProItemDataState>{},
      charty: "",
      activeName: "info",
      showQr: false,
      phoneUrl: "",
      logo: "",
    };
  },
  watch: {
    $route(to, from) {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.id = Number(this.$route.query.id);
      this.proTypeId = Number(this.$route.query.type_id);
      this.phoneUrl = `http://www.kmscszh.com/phone/#/pages/pro_detail/pro_detail?id=${
        this.id
      }&types=${this.proTypeId == 1 ? "pro" : "fund"}`;
      var loading = Loading.service({});
      await getProType(this.proTypeId).then((res) => {
        this.calssifyData = res.tabs;
      });
      await getProDetail(this.proTypeId, this.id).then((res) => {
        this.detail = res;
        // @ts-ignore
        this.logo = this.$urlWithDomain(this.detail.thumb);
      });
      loading.close();
    },
    changeProType(e: ProTypeState) {
      console.log(e);
      this.$router.push({ name: "pro_list", query: { type: `${e.type}` } });
    },
    handleClick(e: any) {
      console.log(e);
      this.activeName = e.name;
    },
  },
});
















import { ProTypeState } from "@/api/model";
import Vue from "vue";
import { PropType } from "vue/types/umd";
export default Vue.extend({
  props: {
    datas: {
      type: Array as PropType<Array<ProTypeState>>,
      required: true,
    },
  },
  data() {
    return {
      selectType: 0,
    };
  },
  methods: {
      select(item:ProTypeState){
          this.selectType = item.id
          this.$emit("click",item)
      }
  },
});


















import Vue from "vue";
import HotPro from "../ProDetail/HotPro.vue";
import NewPro from "../ProDetail/newPro.vue";
export default Vue.extend({
  components: { HotPro, NewPro },
  data() {
    return {
    };
  },
  computed:{
    showLeft:()=>{
      if (document.documentElement.clientWidth <= 500) {
        return false;
      } else {
        return true;
      }
    },
    showRight:()=>{
      if (document.documentElement.clientWidth > 1200) {
        return true;
      } else {
        return false;
      }
    }
  },
  
});



































import { getProPayRecord } from "@/api/api";
import { DonationDetail } from "@/api/model";
import Vue from "vue";
export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      list: <DonationDetail[]>[],
      total: 0,
      page: 1,
      loading:true,
      empty:false
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true
      getProPayRecord(this.id, this.page)
        .then((res) => {
          this.total = res.total;
          this.list = res.list;
        }).finally(()=>{
          this.loading = false
          if(this.total==0){
            this.empty = true
          }
        })
        
    },
    changePage(e: number) {
      this.page = e;
      this.getData();
    },
  },
});





















































































import Vue, { PropType } from "vue";
import ProType from "@/components/ProType/ProType.vue";
import { ProItemDataState, ProTypeState } from "@/api/model";
import { getCharityList, getFundList, getProType } from "@/api/api";
import Card from "@/components/Page/card.vue";
import Pay from "@/components/ProDetail/pay.vue";
import Prograss from "@/components/ProDetail/prograss.vue";
import Commit from "@/components/ProDetail/commit.vue";
import Infor from "@/components/ProDetail/Infor.vue";
import HotPro from "@/components/ProDetail/HotPro.vue";
import MainContent from "@/components/Page/mainContent.vue";
import MImg from "@/components/Page/MImg.vue";
import { Loading } from "element-ui";
export default Vue.extend({
  components: {
    ProType,
    Card,
    Pay,
    Prograss,
    Commit,
    Infor,
    HotPro,
    MainContent,
    MImg,
  },
  data() {
    return {
      id: 0,
      calssifyData: <ProTypeState[]>[],
      list: <Array<ProItemDataState>>[],
      charty: "",
      proTypeId: 0, //1慈善项目，2基金项目
      calssify_id: 0, //当前列表展示分类
      page: 1,
      total: 0,
    };
  },
  created() {
    this.proTypeId = Number(this.$route.query.type);
    getProType(this.proTypeId).then((res) => {
      this.calssifyData = res.tabs;
    });
    this.getData();
  },
  methods: {
    changeProType(e: ProTypeState) {
      console.log(e);
      this.page = 1;
      this.calssify_id = e.id;
      this.list = [];
      this.getData();
    },

    changePage(e: number) {
      this.page = e;
      this.getData();
    },
    async getData() {
      var loading = Loading.service({})
      if (this.proTypeId == 2) {
        await getFundList(this.calssify_id, this.page).then((res) => {
          this.list = res.list;
          this.total = res.total;
        });
      }else{
        await getCharityList(this.calssify_id, this.page).then((res) => {
          this.list = res.list;
          this.total = res.total;
        });
      }
      loading.close()
    },
    nav(detail: ProItemDataState) {
      this.$router.push({ name: "pro_detail", query: { id: `${detail.id}`,type_id:`${this.proTypeId}` } });
    },
  },
});



































import { getArticle } from "@/api/api";
import { Link } from "@/api/model";
import MainContent from "@/components/Page/mainContent.vue";
import MImg from "@/components/Page/MImg.vue";
import HotPro from "@/components/ProDetail/HotPro.vue";
import { Loading } from "element-ui";
import Vue from "vue";
export default Vue.extend({
  components: { HotPro, MainContent, MImg },
  data() {
    return {
      id: 0,
      detail: <Link>{},
    };
  },
  watch: {
    $route(to, from) {
      var loading = Loading.service({});
      this.id = Number(this.$route.params.id || this.$route.query.id);
      getArticle(this.id)
        .then((res) => {
          this.detail = res;
        })
        .catch((e) => {})
        .finally(() => {
          loading.close();
        });
    },
  },
  created() {
    console.log("------------active");
    var loading = Loading.service({});
    this.id = Number(this.$route.params.id || this.$route.query.id);
    getArticle(this.id)
      .then((res) => {
        this.detail = res;
      })
      .catch((e) => {})
      .finally(() => {
        loading.close();
      });
  },
});

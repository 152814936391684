





























import Vue, { PropType } from "vue";
import ProType from "@/components/ProType/ProType.vue";
import { Link, ProItemDataState, ProTypeState } from "@/api/model";
import Card from "@/components/Page/card.vue";
import Pay from "@/components/ProDetail/pay.vue";
import Prograss from "@/components/ProDetail/prograss.vue";
import Commit from "@/components/ProDetail/commit.vue";
import Infor from "@/components/ProDetail/Infor.vue";
import { getArticleList } from "@/api/api";
import { Dictionary } from "vue-router/types/router";
import HotPro from "@/components/ProDetail/HotPro.vue";
import MainContent from "@/components/Page/mainContent.vue";
import { Loading } from "element-ui";
export default Vue.extend({
  components: { ProType, Card, Pay, Prograss, Commit, Infor, HotPro, MainContent },
  data() {
    return {
      id: 0,
      proTypeData: <ProTypeState[]>[],
      list: <Array<Link>>[],
      charty: "",
      page: 1,
      total: 0,
      type_id: "",
    };
  },
  created() {
    this.type_id = this.$route.query.type_id as string;
    this.getData();
  },
  watch: {
    $route(to, from) {
      this.type_id = this.$route.query.type_id as string;
      this.page = 1
      this.getData();
    }
  },
  methods: {
    changePage(e: number) {
      this.page = e;
      this.getData();
    },
    getData() {
      var loading = Loading.service({});
      getArticleList(this.type_id, this.page)
        .then((res) => {
          this.list = res.list;
          this.total = res.total;
        }).finally(()=>{
          loading.close()
        })
    },
    nav(detail: Link) {
      this.$router.push({ name: "article", query: { id: `${detail.id}` } });
    },
  },
});











































import Vue, { PropType } from "vue";
import { Information } from "@/api/model/index";
import { getProInfor } from "@/api/api";
import MImg from "../Page/MImg.vue";
export default Vue.extend({
  components: { MImg },
  props: {
    id: {
      type: Number,
      required: true,
    },
    typeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      list: <Information[]>[],
      total: 0,
      page: 1,
      loading:true,
      empty:false
    };
  },
  created() {
    this.getData();
    console.log("====");
  },
  methods: {
    getData() {
      this.loading = true
      getProInfor(this.typeId,this.id, this.page)
        .then((res) => {
          this.total = res.total;
          this.list = res.list;
        }).finally(()=>{
          this.loading = false
          this.empty = this.total==0
        })
        
    },
    changePage(e: number) {
      this.page = e;
      this.getData();
    },
    nav(detail: Information) {
      this.$router.push({ name: "article", query: { id: `${detail.id}` } });
    },
  },
});

















import { fundList } from "@/api/api";
import { ProItemDataState } from "@/api/model";
import Vue from "vue";
import MImg from "../Page/MImg.vue";
export default Vue.extend({
  components: { MImg },
  data() {
    return {
      list: <ProItemDataState[]>[],
    };
  },
  created(){
      this.getData()
  },
  methods: {
    getData() {
      fundList(1,2)
        .then((res) => {
          this.list = res.list;
        })
        
    },
    nav(detail: ProItemDataState) {
      this.$router.push({ name: "pro_detail", query: { id: `${detail.id}` } });
    },
  },
});
